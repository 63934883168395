import React from 'react';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@material-ui/core/styles';
import { useStaticQuery, graphql } from 'gatsby';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import Img from 'gatsby-image';
import HeroTwo from '../components/HeroTwo';
import { Bold, Italics } from '../components/ProjectBody';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Link } from 'gatsby';
import MailIcon from '@material-ui/icons/Mail';
import logo from '../images/logo.svg';
import favicon from '../images/favicon.svg';

const useStyles = makeStyles((theme) => ({
  header: {
    ...theme.typography.header,
    [theme.breakpoints.down('xs')]: {
      fontSize: '2rem',
    },
  },
  textContainer: {
    maxWidth: '70%',
    margin: 'auto',
    padding: '1rem',
    marginBottom: '2rem',
    [theme.breakpoints.down('xs')]: {
      margin: 0,
      maxWidth: '100%',
    },
  },
  title: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: '2.5rem',
    color: '#536177',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
    },
  },
  subtitle: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '1.75rem',
    fontWeight: theme.typography.fontWeightLight,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.25rem',
    },
  },
  body: {
    ...theme.typography.body,
    textAlign: 'left',
    marginBlockEnd: '4rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  button: {
    marginTop: '1.33em',
    marginBottom: '1.33em',
    height: '2.5rem',
    [theme.breakpoints.down('sm')]: {
      padding: '0.5rem 1rem',
    },
  },
  profilePic: {
    width: '100%',
  },
  container: {
    paddingBlockEnd: '12rem',
    paddingTop: '5rem',
    [theme.breakpoints.down('md')]: {
      paddingBlockEnd: '8rem',
    },
  },
}));
const Heading = ({ children }) => {
  const classes = useStyles();
  return <h2 className={classes.header}>{children}</h2>;
};
const HeadingTwo = ({ children }) => {
  const classes = useStyles();
  return <h3 className={classes.headerTwo}>{children}</h3>;
};
const Text = ({ children }) => {
  const classes = useStyles();
  return <p className={classes.body}>{children}</p>;
};

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
    [MARKS.ITALIC]: (text) => <Italics>{text}</Italics>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) =>
      node.content.value !== '' && <Text>{children}</Text>,
    [BLOCKS.HEADING_2]: (node, children) => <Heading>{children}</Heading>,
    [BLOCKS.HEADING_3]: (node, children) => <HeadingTwo>{children}</HeadingTwo>,
  },
};

const AboutPage = () => {
  const classes = useStyles();
  const data = useStaticQuery(graphql`
    query {
      contentfulAbout {
        body {
          raw
        }
        profile {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
  `);
  const body = data.contentfulAbout.body;
  return (
    <div>
      <HeroTwo title="About Me" />
      <Container className={classes.container}>
        <Helmet>
          <meta charSet="utf-8" />
          <link rel="icon" href={favicon} />
          <meta property="og:title" content="Nabhas Alakkat | Portfolio" />
          <meta
            property="og:description"
            content="my web developer portfolio"
          />
          <meta property="og:image" content={logo} />
          <meta property="og:url" content="https://alakkat.com/about" />
          <title>Nabhas Alakkat | About</title>
          <link rel="canonical" href="https://alakkat.com/about" />
        </Helmet>
        <Paper className={classes.textContainer}>
          <Grid container justify="center">
            <Grid item md={9}>
              <Grid container direction="column">
                <Grid item>
                  <h2 className={classes.title}>Hi, Im Nabhas!</h2>
                </Grid>
                <Grid item>
                  <h3 className={classes.subtitle}>
                    I'm a web developer from Charlotte, North Carolina.
                  </h3>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={3} xs={8}>
              <Img
                className={classes.profilePic}
                fluid={data.contentfulAbout.profile.fluid}
              />
            </Grid>
          </Grid>
          {body && renderRichText(body, options)}
          <Grid
            container
            direction="column"
            className={classes.buttonContainer}
          >
            <h4 className={classes.subtitle}>
              If you are interested in working with me, let’s get in touch!
            </h4>
            <Button
              disableRipple
              className={classes.button}
              variant="outlined"
              color="primary"
              component={Link}
              to="/contact"
              startIcon={<MailIcon />}
            >
              Contact Me
            </Button>
          </Grid>
        </Paper>
      </Container>
    </div>
  );
};

export default AboutPage;
